import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
} from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

import { ROUTING_MAP } from '../../models/constants/routing-map';
import { AlertStatus } from '../../models/enums/alert-status.enum';
import { AlertService } from '../../services/alert.service';

export interface LinkShareParams {
  categoryUuid?: string;
  pathologyUuid?: string;
  pathologyTypeUuid?: string;
  bundleUuid?: string;
  isPathology?: boolean;
}

export enum LinkType {
  Article = 'ARTICLE',
  Pathology = 'PATHOLOGY',
  PathologyType = 'PATHOLOGY_TYPE',
  Item = 'ITEM',
}

@Component({
  selector: 'app-link-share',
  templateUrl: './link-share.component.html',
  styleUrls: ['./link-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent],
})
export class LinkShareComponent {
  @Input() public linkType: LinkType;

  @Input() public linkShareParams: LinkShareParams;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private alertService: AlertService
  ) {}

  public copyLink(event: Event): void {
    event.stopPropagation();

    let link = `${location.origin}/`;

    switch (this.linkType) {
      case LinkType.Article:
        link += `articles/${this.linkShareParams.categoryUuid}/${this.linkShareParams.pathologyUuid}`;
        break;

      case LinkType.Pathology:
        link += `${
          this.linkShareParams.isPathology
            ? ROUTING_MAP.pathology
            : ROUTING_MAP.anatomy
        }/${this.linkShareParams.categoryUuid}/${
          this.linkShareParams.pathologyUuid
        }`;
        break;

      case LinkType.PathologyType:
        link += `${ROUTING_MAP.models}/${this.linkShareParams.categoryUuid}/${this.linkShareParams.pathologyUuid}/${this.linkShareParams.pathologyTypeUuid}`;
        break;

      case LinkType.Item:
        link += `${ROUTING_MAP.models}/${this.linkShareParams.categoryUuid}/${this.linkShareParams.pathologyUuid}/${this.linkShareParams.pathologyTypeUuid}/${this.linkShareParams.bundleUuid}`;
        break;
    }

    link = `${link}/?lang=${this.locale}`;

    navigator.clipboard.writeText(link).then(() => {
      this.alertService.show({
        message: $localize`Ссылка успешно скопирована в буфер обмена`,
        status: AlertStatus.Success,
      });
    });
  }
}
