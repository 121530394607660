import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
  selector: 'app-in-progress-popup',
  templateUrl: './in-progress-popup.component.html',
  styleUrls: ['./in-progress-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CloseButtonComponent],
})
export class InProgressPopupComponent {
  constructor(private dialogRef: DialogRef<any, InProgressPopupComponent>) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
