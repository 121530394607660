import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Metadata } from '@app/shared/models/interfaces/metadata.interface';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private meta: Meta,
    private title: Title
  ) {}

  public setMetadata(config: Metadata = {}): void {
    if (config.meta) {
      config.meta.forEach(unit => {
        this.meta.updateTag(unit);
      });
    }

    if (config.title) {
      this.title.setTitle(config.title);
    }
  }
}
