import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import {
  Category,
  CategoryWithPathologies,
} from '../../models/interfaces/category';

@Injectable({
  providedIn: 'root',
})
export class CategoryAPIService {
  constructor(private http: HttpClient) {}

  public getCategories$(): Observable<Category[]> {
    return this.http.get<Category[]>(
      `${environment.apiUrl}/web/categories/?articles=false`
    );
  }

  public getCategoryData$(uuid: string): Observable<CategoryWithPathologies> {
    return this.http.get<CategoryWithPathologies>(
      `${environment.apiUrl}/web/categories/${uuid}`
    );
  }
}
