<div class="in-progress">
  <app-close-button class="close" (click)="closeDialog()"></app-close-button>

  <h2 i18n class="in-progress__title">Эта модель скоро будет более точной</h2>

  <p i18n class="in-progress__text-content">
    Сейчас мы работаем над тем, чтобы как можно быстрее сделать улучшенную
    версию
  </p>
</div>
